import domReady from '@wordpress/dom-ready';

domReady(() => {
  const inputBar = document.getElementById('findstrSearchbar');
  const inputModal = document.getElementById('findstrSearchModal');
  const resultsWrapperBar = document.querySelector(
    '.search-window__results-wrapper.wrapper-bar'
  );
  const resultsWrapperModal = document.querySelector(
    '.search-window__results-wrapper.wrapper-modal'
  );

  if (inputBar && resultsWrapperBar) {
    inputBar.addEventListener('input', () => {
      if (inputBar.value.trim() === '') {
        resultsWrapperBar.style.display = 'none';
      } else {
        resultsWrapperBar.style.display = 'block';
      }
    });
  }

  if (inputModal && resultsWrapperModal) {
    inputModal.addEventListener('input', () => {
      if (inputModal.value.trim() === '') {
        resultsWrapperModal.style.display = 'none';
      } else {
        resultsWrapperModal.style.display = 'block';
      }
    });
  }

  /* popular requests init search on click */
  const buttons = document.querySelectorAll('.findstr-search-request');

  if (0 < buttons.length) {
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        const searchWord = button.getAttribute('data-search-word');

        if (searchWord) {
          const baseUrl = `${window.location.protocol}//${window.location.host}/`;
          const queryParams = `?s=${encodeURIComponent(
            searchWord
          )}&findstr[page]=1`;

          // Redirectoin vers l'URL
          window.location.href = baseUrl + queryParams;
        }
      });
    });
  }

  //Do not display empty result on fisrt loading page
  const findstr = window.findstr || {};

  findstr.hooks.addFilter(
    'findstrEmptyResultsMessageOnInit',
    'findstr-search-overlay',
    function (setEmptyAlertOnInit, group) {
      if ('globalsearch-bar' === group || 'globalsearch-modal' === group) {
        return false;
      }
      return setEmptyAlertOnInit;
    }
  );

  findstr.hooks.addFilter(
    'urlManagementDisabled',
    'geonomeQuebec',
    (disabled, group) => {
      if ('globalsearch' !== group) {
        return true;
      }
      return disabled;
    }
  );

  const searchInput = document.getElementById('findstrSearchInputResultPage');
  const resultSpan = document.getElementById('result-search-query');
  const noTermSpan = document.getElementById('result-search-no_term');
  const withTermSpan = document.getElementById('result-search-with_term');
  const angleQuotes1 = document.getElementById('angle_quotes1');
  const angleQuotes2 = document.getElementById('angle_quotes2');

  findstr.hooks.addAction(
    'searchResults',
    'displayQueryandCountResults',
    () => {
      if (withTermSpan && noTermSpan && angleQuotes1 && angleQuotes2) {
        if (searchInput && searchInput.value !== '') {
          noTermSpan.style.display = 'none';
          withTermSpan.style.display = 'inline';
          angleQuotes1.style.display = 'inline';
          angleQuotes2.style.display = 'inline';
        } else {
          withTermSpan.style.display = 'none';
          angleQuotes1.style.display = 'none';
          angleQuotes2.style.display = 'none';
          noTermSpan.style.display = 'inline';
        }
      }
      if (resultSpan && searchInput) {
        resultSpan.textContent = searchInput.value;
      }

      //result count
      const field = document.querySelector('.findstr-field-results-count');
      if (field) {
        const resulCount = field.querySelector('.findstr-results-count');

        if (resulCount) {
          // si on a pas de term recherche
          if (searchInput && searchInput.value === '') {
            resulCount.style.display = 'none';
          } else {
            resulCount.style.display = 'inline';
          }
        }
      }
    }
  );

  // Synchronsier avec l'input de recherche
  if (searchInput && resultSpan) {
    searchInput.addEventListener('input', (event) => {
      if (resultSpan) {
        if (withTermSpan) {
          resultSpan.style.display = 'inline';
        } else {
          resultSpan.style.display = 'none';
        }
      }
      resultSpan.textContent = event.target.value;
    });
  }
});
